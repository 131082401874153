import { CognitoJwtVerifier } from 'aws-jwt-verify';
import { useEffect, useState } from 'react';
import './App.css';

function App() {
  const COGNITO_USER_POOL_ID = "us-east-1_0Y6ErGRis";
  const COGNITO_USER_POOL_DOMAIN = "sbxb.auth.us-east-1.amazoncognito.com";
  const COGNITO_CLIENT_ID = 'p2iao6ljav6o6669q8cbkm0h7';
  const COOKIE_BASE = `CognitoIdentityServiceProvider.${COGNITO_CLIENT_ID}`;

  const jwtVerifier = CognitoJwtVerifier.create({
    userPoolId: COGNITO_USER_POOL_ID,
    clientId: COGNITO_CLIENT_ID,
    tokenUse: 'id',
  });

  const AUTH_GATEWAY_URL = "https://auth.sbxb.vikred.people.aws.dev"

  const [idToken, setIdToken] = useState()
  const [accessToken, setAccessToken] = useState()
  const [userMessage, setUserMessage] = useState()
  const [userInfo, setUserInfo] = useState("Loading...")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIdTokenFromCookie()
    if (idToken) {
      fetchUserInfo()
    }
  }, [idToken])

  const url = new URL(AUTH_GATEWAY_URL)

  // signIn
  url.pathname = "/signIn"
  url.searchParams.set("redirect_uri", '/lol')
  const signInUrl = url.toString()

  url.searchParams.set("redirect_uri", "https://aws.com/training")
  const invalidSignInUrl = url.toString()

  url.searchParams.set("redirect_uri", window.location.href)
  url.searchParams.set("email_verification_required", "true")
  const signInUrlWithAdditionalParams = url.toString()

  // singOut
  url.pathname = "/signOut"
  url.searchParams.set("redirect_uri", window.location.href)
  const signOutUrl = url.toString()

  url.pathname = "/refreshToken"
  url.searchParams.set("redirect_uri", window.location.href)
  const refreshTokenUrl = url.toString()

  const setIdTokenFromCookie = () => {
    const cookies = {}
    document.cookie
      .split(";")
      .forEach(c => {
        const [k, v] = c.trim().split("=")
        cookies[k] = v
      })
    const idTokenCookieNamePostfix = '.idToken';
    const accessTokenCookieNamePostfix = '.accessToken';

    for (const name in cookies) {
      if (name.startsWith(COOKIE_BASE)) {
        if (name.endsWith(idTokenCookieNamePostfix)) {
          setIdToken(cookies[name])
        }
        if (name.endsWith(accessTokenCookieNamePostfix)) {
          setAccessToken(cookies[name])
        }
      }
    }
  }

  const fetchUserInfo = async () => {
    try {
      const userInfoResp = await fetch(`https://${COGNITO_USER_POOL_DOMAIN}/oauth2/userInfo`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
      const userInfoRespJson = await userInfoResp.json()
      setUserMessage(`Logged in as ${userInfoRespJson["username"]} <${userInfoRespJson.email}>`)
      setUserInfo(userInfoRespJson)
    } catch (err) {
      console.log("Cannot get info", err)
      setUserInfo(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>SBXB AG Client</h1>
        <p>
          Works with auth gateway deployed on
          <br/>
          <a href={AUTH_GATEWAY_URL}>{AUTH_GATEWAY_URL}</a>
        </p>
      </header>
      <main>
        {
          !idToken
            ? <>
              <ul>
                <li>
                  <a href={signInUrl}>Sign In</a>
                </li>
                <li>
                  <a href={invalidSignInUrl}>Sign In (URL with random redirect_uri)</a>
                </li>
                <li>
                  <a href={signInUrlWithAdditionalParams}>Sign In (URL with additional params)</a>
                </li>
              </ul>
            </>
            : <>
              <p>{userMessage}</p>

              <div class="auth-actions">
                <a href={signOutUrl}>Sign Out</a>
                <a href={refreshTokenUrl}>Refresh Token</a>
              </div>

              <h2>Token Info</h2>
              <pre className="token-info">
                refresh_token: HttpOnly (see in browser console)

                access_token: {accessToken}

                id_token: {idToken}
              </pre>

              <h2>User Info</h2>
              <p>
                Fetched by frontend directly from AWS Cognito's <a href="https://docs.aws.amazon.com/cognito/latest/developerguide/userinfo-endpoint.html">/oauth2/userInfo</a> endpoint
              </p>
              <pre className="token-info">
                {JSON.stringify(userInfo)}
              </pre>
            </>
        }
      </main>
    </div>
  );
}

export default App;
